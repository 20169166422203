import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Product from "../components/Product";
import CourseBanner from "../components/CourseBanner";
const Solid = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const OpticalData = {
    data: [
      {
        img: "/images/Solid/img1.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img2.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img3.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img4.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img5.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img6.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img7.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img8.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img9.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img10.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img11.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img12.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img13.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img14.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img15.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img16.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img17.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img18.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img19.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img20.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img21.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img22.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img23.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img24.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img25.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Solid/img26.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
    
    ],
  };

  const bannerData = {
    image: "/images/solid-banner.jpg",
    heading: "Solid",
    heading2: "Lorem ipsum dolor sit amet consectetur adipisicing.",
    para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique atque amet consequatur facere quod iure obcaecati, nihil rerum inventore culpa incidunt tenetur, aut eos recusandae, ullam eaque est voluptatum ea veniam dolorem perspiciatis quos sit excepturi ab! Inventore id quas dignissimos eaque, tenetur ratione laboriosam excepturi sint, optio ex magni!",
  };
  return (
    <div className="page-content">
     
      <CourseBanner {...bannerData} />
      <section className="bg2 product-container-wrapper " id="optical-showroom">
        <div className="container py-5">
          
          <Product ProductData={OpticalData} name="Optical Showroom Design" />
        </div>
      </section>
    </div>
  );
};

export default Solid;
