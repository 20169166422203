import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./slick.css";
import "./slick-theme.css";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Transitional from "./pages/CustomCreations";
import Modern from "./pages/Modern";
import Unshaped from "./pages/Unshaped";
import Traditional from "./pages/Traditional";
import Solid from "./pages/Solid ";



function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/transitional" element={<Transitional />} />
        <Route path="/modern" element={<Modern />} />
        <Route path="/unshaped" element={<Unshaped />} />
        <Route path="/traditional" element={<Traditional />} />
        <Route path="/solid" element={<Solid />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
