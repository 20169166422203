import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import CourseBanner from "../components/CourseBanner";
import OurServices from "../components/OurServices";

const About = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const bannerData = {
    image: "/images/about-banner.jpg",
    heading: "About Us",
    heading2:"Discover Our Story",
   para: "Founded in the heart of Agra, Agra Rugs has been a beacon of traditional Indian rug-making for over 2 years. Our journey began with a commitment to preserving the heritage of handmade rugs, and today, we take pride in delivering exquisite carpets to homes worldwide.",
  };
  return (
    <div className="page-content">
    
      <CourseBanner {...bannerData} />
      <section className="bg2">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6">
              <div className="wrapper">
                <h2>Agra Rugs</h2>
                <p>
                Welcome to Agra Rugs, where tradition meets artistry. Nestled in the historic city of Agra, our handmade rugs and carpets bring the rich heritage of Indian craftsmanship into your home. Each piece is a masterpiece, blending intricate designs, premium materials, and unmatched quality.</p>
                <p><strong>Why Choose Agra Rugs?</strong></p>
                <p>
                Authentic Craftsmanship: Every rug is handwoven by skilled artisans with generations of expertise.
Premium Quality: Only the finest materials are used to create durable and luxurious carpets.
Exclusive Designs: From traditional Persian-inspired patterns to modern styles, we offer a wide range to suit every taste.
                </p>
          
              
              </div>
            </div>
           
            <div className="col-md-6">
              <div className="img-wrapper">
                <img src="/images/about-us.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <OurServices />
      <section className="bg2">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6">
              <div className="wrapper">
                <h2>Our Values</h2>

                <p>
                Celebrating artistry and tradition.</p>
              <p>  Ensuring ethical sourcing and sustainable practices.</p>
              <p>  Providing exceptional quality and personalized service.

               
                </p>
               
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="img-wrapper">
                <img src="/images/commitment.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-5">
              <div className="img-wrapper">
                <img src="/images/purpose.jpg" alt="" />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6">
              <div className="wrapper">
                <h2>Our Mission</h2>

                <p>
                To enrich homes and spaces with the timeless beauty of handcrafted rugs, while supporting local artisans and their crafts.
                </p>
               
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
