import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Product from "../components/Product";
import CourseBanner from "../components/CourseBanner";
const Modern = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const OpticalData = {
    data: [
      {
        img: "/images/Modern/img1.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img2.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img3.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img4.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img5.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img6.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img7.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img8.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img9.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img10.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img11.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img12.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img13.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img14.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img15.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img16.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img17.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img18.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img19.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img20.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img21.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img22.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img23.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img24.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img25.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img26.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Modern/img27.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
    ],
  };

  const bannerData = {
    image: "/images/modern-banner.jpg",
    heading: "Modern Rugs",
    heading2: "Lorem ipsum dolor sit amet consectetur adipisicing.",
    para: "Sleek, contemporary designs for minimalist and chic interiors.",
  };
  return (
    <div className="page-content">
    
      <CourseBanner {...bannerData} />
      <section className="bg2 product-container-wrapper " id="optical-showroom">
        <div className="container py-5">
         
          <Product ProductData={OpticalData} name="Optical Showroom Design" />
        </div>
      </section>
    </div>
  );
};

export default Modern;
