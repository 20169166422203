import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Product from "../components/Product";
import CourseBanner from "../components/CourseBanner";
const Traditional = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const OpticalData = {
    data: [
      {
        img: "/images/Traditional/img1.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img2.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img3.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img4.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img5.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img6.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img7.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img8.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img9.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img10.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img11.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img12.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img13.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img14.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img15.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img16.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img17.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img18.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img19.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img20.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img21.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img22.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img23.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img24.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img25.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img26.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Traditional/img27.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
    ],
  };

  const bannerData = {
    image: "/images/traditional-banner.jpg",
    heading: "Traditional Rugs",
    heading2: "Lorem ipsum dolor sit amet consectetur adipisicing.",
    para: " Featuring classic motifs and intricate patterns inspired by Persian and Mughal art.",
  };
  return (
    <div className="page-content">
      
      <CourseBanner {...bannerData} />
      <section className="bg2 product-container-wrapper " id="optical-showroom">
        <div className="container py-5">
        
          <Product ProductData={OpticalData} name="Optical Showroom Design" />
        </div>
      </section>
    </div>
  );
};

export default Traditional;
