import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Product from "../components/Product";
import CourseBanner from "../components/CourseBanner";
const Transitional = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const OpticalData = {
    data: [
      {
        img: "/images/Transitional/img1.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img2.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img3.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img4.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img5.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img6.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img7.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img8.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img9.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img10.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img11.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img12.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img13.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img14.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img15.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img16.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img17.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img18.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img19.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img20.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img21.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img22.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img23.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img24.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img25.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img26.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/Transitional/img27.jpg",
        name: "imara",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
    ],
  };

  const bannerData = {
    image: "/images/transitional-banner.jpg",
    heading: "Custom Creations",
    heading2: "Lorem ipsum dolor sit amet consectetur adipisicing.",
    para: "Bespoke rugs tailored to your unique preferences in size, design, and color.",
  };
  return (
    <div className="page-content">
     
      <CourseBanner {...bannerData} />
      <section className="bg2 product-container-wrapper " id="optical-showroom">
        <div className="container py-5">
         
          <Product ProductData={OpticalData} name="Optical Showroom Design" />
        </div>
      </section>
    </div>
  );
};

export default Transitional;
